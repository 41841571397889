import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

type ActionButtonProps = {
	label: string,
	icon: JSX.Element,
	to: string,
	variant?: string,
}

const ActionButton = ({ label, icon, to, variant = "dark" }: ActionButtonProps) => {
	const navigate = useNavigate();
	return (
		<Button variant={variant} className="d-flex flex-row justify-content-between align-items-center w-100" onClick={() => navigate(to)}>
			<div className="text-nowrap">{label}</div>
			<div className="bg-dark rounded-circle p-2 text-light">{icon}</div>
		</Button>
	);
};

export default ActionButton;