import Stack from 'react-bootstrap/Stack';
import { CreditCard, Shuffle, ArrowRepeat } from 'react-bootstrap-icons';
import Modal from '../../components/Modal';
import IconButton from '../../components/IconButton';

const Send = () => (
	<Modal>
		<h3 className="text-white text-center">Transfer</h3>
		<Stack gap={3}>
			<IconButton variant="light" size="lg" icon={<CreditCard size={16} />} label="To card by number" to="/" />
			<IconButton variant="light" size="lg" icon={<ArrowRepeat size={16} />} label="Currency/swap" to="/" />
			<IconButton variant="light" size="lg" icon={<Shuffle size={16} />} label="From my accounts" to="/" />
		</Stack>
	</Modal>
);

export default Send;