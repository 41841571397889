import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApiThunk, request } from '../api';
import type { User } from '../types/User';

export type LoginRequest = {
	email: string,
	password: string,
};

export type LoginResponse = {
	user: User,
	apiKey: string,
};

export const login = createAsyncThunk("user/login", (data: LoginRequest) => request<LoginRequest, LoginResponse>("user/login", "POST", "", data));


export const changePassword = createApiThunk<{old: string, new:string}, {}>("user/password", "POST");
export const changeAddress = createApiThunk<{address: string}, {}>("user/address", "POST");
