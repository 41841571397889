import { useNavigate } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import Card from 'react-bootstrap/Card';
import { Bank, CreditCard } from 'react-bootstrap-icons';
import type { EntityId } from '@reduxjs/toolkit';
import { useAccount } from '../api/selectors/accounts';
import type { Account } from '../api/types/Account';

type AccountCardProps = {
	id: EntityId,
};

const AccountCard = ({ id }: AccountCardProps) => {
	const {name, currency, color } = useAccount(id) as Account;
	const navigate = useNavigate();
	return (
		<Card style={{ backgroundColor: color }} onClick={() => navigate(`/accounts/view/${id}`)}>
			<Card.Body className="d-flex flex-column justify-content-between">
				<div className="d-flex flex-row justify-content-between">
					{name}
					<Bank size={16} />
				</div>
				<div>
					<Card.Subtitle>{currency} balance</Card.Subtitle>
					<Card.Title className="mb-0">
						<FormattedNumber value={0} style="currency" currency={currency} />
					</Card.Title>
				</div>
			</Card.Body>
		</Card>
	);
};

export default AccountCard;