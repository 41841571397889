import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Plus } from 'react-bootstrap-icons';

const NewAccountButton = () => {
	const navigate = useNavigate();
	return (
		<Button className="new-account-btn" onClick={() => navigate("/accounts/new")}>
			<div className="icon">
				<Plus size={16} />
			</div>
			<small>Open new product</small>
		</Button>
	)
};

export default NewAccountButton;