import { createSelector, EntityId } from '@reduxjs/toolkit';
import type { RootState } from '../reducer';
import { useAppSelector } from '../api';

const getMap = (state:RootState) => state.accounts.entities;
const getID = (state:RootState, id:EntityId) => id;

const getAccount = createSelector([getMap, getID], (entities, id) => entities[id]); 
const getAccountIDs = (state:RootState) => state.accounts.ids;
const getAccountLoading = (state:RootState) => state.accounts.loading; 

const useAccountIDs = () => useAppSelector(getAccountIDs);
const useAccount = (id:EntityId) => useAppSelector(state => getAccount(state, id));
const useAccountLoading = () => useAppSelector(getAccountLoading);

export {
	getAccountIDs,
	getAccount,
	getAccountLoading,
	
	useAccountIDs,
	useAccount,
	useAccountLoading,
};