import { NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Figure from 'react-bootstrap/Figure';
import { useUser } from '../api/selectors/user';
import { useLogout } from '../api/entities/user';

const UserMenu = () => {
	const { name, surname, photo } = useUser();
	const logout = useLogout();
	return (
		<Dropdown className="user-menu">
			<Dropdown.Toggle>
				<Figure className="hstack bg-white gap-1 px-4 py-3 rounded-2 text-uppercase fs-5 mb-0">
					<Figure.Caption>
						<div>{name}</div>
						<div>{surname}</div>
					</Figure.Caption>
					<Figure.Image src={photo || undefined} width={36} height={36} roundedCircle className="bg-dark" />
				</Figure>
			</Dropdown.Toggle>
			<Dropdown.Menu className="w-100">
				<div className="vstack gap-1">
					<Dropdown.Item as={NavLink} to="/user" className="py-4 rounded-2">Settings</Dropdown.Item>
					<Dropdown.Item className="py-4 rounded-2" onClick={logout}>Logout</Dropdown.Item>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default UserMenu;