import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

const store = configureStore({
	reducer: reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		thunk: true,
	}),
});

export type AppDispatch = typeof store.dispatch;

export default store;
