import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { ChevronRight } from 'react-bootstrap-icons';

type IconButtonProps = {
	icon: JSX.Element,
	label: string,
} & ButtonProps & LinkProps;

const IconButton = ({ icon, label, ...props }: IconButtonProps) => (
	<Button as={Link as ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>> & "symbol"} className="d-flex flex-row justify-content-between align-items-center gap-4" {...props}>
		<div className="bg-dark text-light rounded-circle lh-1 p-2">{icon}</div>
		<div className="flex-fill text-start">{label}</div>
		<ChevronRight size={16} />
	</Button>
);

export default IconButton;