import { useState, useEffect, FormEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Offcanvas from '../../components/Offcanvas';
import ColorPalette from '../../components/ColorPalette';
import { useEditAccount, useDeleteAccount } from '../../api/entities/accounts';
import { useAccount } from '../../api/selectors/accounts';
import type { Account } from '../../api/types/Account';
import type { Color } from '../../api/types/Color';

const Edit = () => {
	const { id } = useParams();
	const account = useAccount(Number(id)) as Account;
	const [name, setName] = useState<string>(account.name);
	const [color, setColor] = useState<Color>(account.color);
	const [valid, setValid] = useState<boolean>(false);
	useEffect(() => setValid(name != ""), [name]);
	const navigate = useNavigate();
	const editAccount = useEditAccount();
	const deleteAccount = useDeleteAccount();
	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		editAccount( account.id, name, color );
		return navigate("/");
	};
	const onDelete = () => deleteAccount(account.id);
	return (
		<Offcanvas>
			<Form onSubmit={onSubmit}>
				<div className="bg-light text-dark p-5">
					<h4 className="mb-5">Edit account</h4>
					<Stack gap={4} className="account-form m-auto">
						<Form.Group>
							<Form.Label>Account name</Form.Label>
							<Form.Control type="text" value={name} onChange={e => setName(e.currentTarget.value)} />
						</Form.Group>
						<Form.Group>
							<Form.Label>Color label</Form.Label>
							<ColorPalette value={color} onChange={setColor} />
						</Form.Group>
					</Stack>
				</div>
				<div className="bg-white p-5">
					<Button type="submit" variant="dark" size="lg" disabled={!valid} className="d-block w-50 mx-auto mb-5">Save changes</Button>
					<Button type="button" variant="light" size="lg" className="d-block w-50 mx-auto text-danger" onClick={onDelete}>Delete account</Button>
				</div>
			</Form>
		</Offcanvas>
	);
};

export default Edit;