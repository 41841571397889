import { useState, FormEvent } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useLoginStatus } from '../api/selectors/user';
import { useLogin } from '../api/entities/user';
import { LoadingStatus } from '../api/types/LoadingStatus';

const LoginPage = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const loading = useLoginStatus();
	const login = useLogin();
	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		login(email, password);
	};
	return (
		<Container>
			<Row>
				<Col sm={{ span: 6, offset: 3 }}>
					<Form className="form" onSubmit={onSubmit}>
						<Stack gap={3} className="pt-5">
							<h2>Welcome back!</h2>
							<FloatingLabel label="Email">
								<Form.Control type="text" value={email} placeholder="Email" onChange={e => setEmail(e.target.value)} isInvalid={loading == LoadingStatus.FAILURE} />
								<Form.Control.Feedback type="invalid" tooltip={true}>Incorrect email/password pair</Form.Control.Feedback>
							</FloatingLabel>
							<FloatingLabel label="Password">
								<Form.Control type="password" value={password} placeholder="Password" onChange={e => setPassword(e.target.value)} isInvalid={loading == LoadingStatus.FAILURE} />
								<Form.Control.Feedback type="invalid" tooltip={true}>Incorrect email/password pair</Form.Control.Feedback>
							</FloatingLabel>
							<Button type="submit" variant="dark" size="lg" className="w-50 mx-auto">Login</Button>
						</Stack>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default LoginPage;