import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useChangeAddress } from '../../api/entities/user';
import { useUser, useAddressStatus } from '../../api/selectors/user';
import { LoadingStatus } from '../../api/types/LoadingStatus';

const Address = () => {
	const loading = useAddressStatus();
	const user = useUser();
	const [address, setAddress] = useState(user.address);
	const navigate = useNavigate();
	const changeAddress = useChangeAddress();
	const onSubmit = () => {
		changeAddress(address);
		setTimeout(() => navigate("/"), 5000);
	};
	const onCancel = () => navigate("/");
	return (
		<div className="w-50 h-100 d-flex flex-column justify-content-between align-items-stretch p-5">
			<h4>Delivery address</h4>
			<div className="d-flex flex-column gap-2">
				<Form.Group>
					<Form.Label>Address</Form.Label>
					<Form.Control value={address} onChange={e => setAddress(e.currentTarget.value)} as="textarea" rows={3} isValid={loading == LoadingStatus.SUCCESS} isInvalid={loading == LoadingStatus.FAILURE} />
					<Form.Control.Feedback type="valid" tooltip={false}>New address saved</Form.Control.Feedback>
					<Form.Control.Feedback type="invalid" tooltip={false}>Error saving address</Form.Control.Feedback>
				</Form.Group>
			</div>
			<div className="d-flex flex-column gap-2">
				<Button variant="dark" onClick={onSubmit}>Save changes</Button>
				<Button variant="white" onClick={onCancel}>Cancel</Button>
			</div>
		</div>
	);
};

export default Address;