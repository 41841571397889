import { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAccountIDs, useAccountLoading } from '../api/selectors/accounts';
import { useLoadAccounts } from '../api/entities/accounts';
import AccountCard from '../components/AccountCard';
import NewAccountButton from '../components/NewAccountButton';
import Spinner from 'react-bootstrap/Spinner';
import { LoadingStatus } from '../api/types/LoadingStatus';

const Products = () => {
	const accountIDs = useAccountIDs();
	const accountLoading = useAccountLoading();
	const loadAccounts = useLoadAccounts();
	useEffect(() => {
		console.log(accountLoading);
		if (accountLoading == LoadingStatus.IDLE) {
			loadAccounts();
		}
	}, [loadAccounts, accountLoading]);
	return (
		<Row className="products row-cols-auto">
			{(accountLoading == LoadingStatus.SUCCESS)
				? (accountIDs.map(id => <Col key={id}><AccountCard id={id} /></Col>))
				: <Spinner />
			}
			<Col><NewAccountButton /></Col>
		</Row>
	);
};

export default Products;