import colors, {Color} from '../api/types/Color';
import css from "./ColorPalette.module.scss";

type ColorPaletteProps = {
	value: Color,
	onChange: (color:Color) => void,
};

const ColorPalette = ({ value, onChange }: ColorPaletteProps) => {
	return (
		<div className={css.palette}>
			{colors.map(color => (
				<div key={color} className={`${css.wrapper} ${color === value ? css.active: ""}`} onClick={e => onChange(color)}>
					<div className={css.color} style={{ backgroundColor: color }}></div>
				</div>
			))}
		</div>
	);
};

export default ColorPalette;