import { useApiKey } from '../api/selectors/user';

type AuthProviderProps = {
	loginPage: JSX.Element,
	children: JSX.Element,
};

const AuthProvider = ({ loginPage, children }:AuthProviderProps) => {
	const apiKey = useApiKey();
	return apiKey.length ? children : loginPage;
};

export default AuthProvider;