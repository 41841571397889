import { combineReducers } from 'redux';
import user from './entities/user';
import accounts from './entities/accounts';
import cards from './entities/cards';

const reducer = combineReducers({
	user: user.reducer,
	accounts: accounts.reducer,
	cards: cards.reducer,
});

export type RootState = ReturnType<typeof reducer>; 

export default reducer;