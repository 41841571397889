import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useChangePassword } from '../../api/entities/user';
import { usePasswordStatus } from '../../api/selectors/user';
import { LoadingStatus } from '../../api/types/LoadingStatus';

const Password = () => {
	const loading = usePasswordStatus();
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const navigate = useNavigate();
	const changePassword = useChangePassword();
	const onSubmit = () => {
		changePassword(oldPassword, newPassword);
		setTimeout(() => navigate("/"), 5000);
	};
	const onCancel = () => navigate("/");
	return (
		<div className="d-flex flex-column justify-content-between align-items-stretch p-5 w-50 h-100">
			<h4>Edit password</h4>
			<div className="d-flex flex-column gap-4">
				<Form.Group>
					<Form.Label>Old password</Form.Label>
					<Form.Control type="password" value={oldPassword} onChange={e => setOldPassword(e.currentTarget.value)} isValid={loading == LoadingStatus.SUCCESS} isInvalid={loading == LoadingStatus.FAILURE} />
				</Form.Group>
				<Form.Group>
					<Form.Label>New password</Form.Label>
					<Form.Control type="password" value={newPassword} onChange={e => setNewPassword(e.currentTarget.value)} isValid={loading == LoadingStatus.SUCCESS} isInvalid={loading == LoadingStatus.FAILURE} />
					<Form.Control.Feedback type="valid" tooltip={false}>New password saved</Form.Control.Feedback>
					<Form.Control.Feedback type="invalid" tooltip={false}>Error saving password</Form.Control.Feedback>
				</Form.Group>
			</div>
			<div className="d-flex flex-column gap-2">
				<Button type="button" variant="dark" onClick={onSubmit}>Save changes</Button>
				<Button type="button" variant="white" onClick={onCancel}>Cancel</Button>
			</div>
		</div>
	);
};

export default Password;