import { Outlet } from 'react-router-dom';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { ArrowUpRight, Plus } from 'react-bootstrap-icons';
import UserMenu from '../components/UserMenu';
import TotalBalance from '../components/TotalBalance';
import Products from '../components/Products';
import ActionButton from '../components/ActionButton';

const Dashboard = () => (
	<div data-bs-theme="light">
		<Navbar fixed="top">
			<Container>
				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
					<UserMenu />
				</Navbar.Collapse>
			</Container>
		</Navbar>
		<Outlet />
		<header><TotalBalance /></header>
		<Container as="section">
			<Row className="g-0">
				<Col lg={6} xl={8}>
					<Products />
				</Col>
				<Col lg={6} xl={4}>
					<Row className="products row-cols-auto">
						<ActionButton key="send" label="Send" icon={<ArrowUpRight size={16} />} to="/accounts/send" variant="dark" />,
						<ActionButton key="top-up" label="Top up" icon={<Plus size={16} />} to="/accounts/top-up" variant="white" />
					</Row>
				</Col>
			</Row>
		</Container>
		<footer className="bg-body-secondary text-light-emphasis pt-2 text-uppercase position-fixed bottom-0 w-100">
		</footer>
	</div>
);

export default Dashboard;