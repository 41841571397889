import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import type { PayloadAction, Update } from '@reduxjs/toolkit';
import type { Account } from '../types/Account';
import type { Color } from '../types/Color';
import { LoadingStatus } from '../types/LoadingStatus';
import { useAppDispatch } from '../api';
import { loadAccounts, createAccount, editAccount, deleteAccount } from '../actions/accounts';

type AccountState = EntityState<Account> & {
	loading: LoadingStatus
};

const adapter = createEntityAdapter<Account>();

const initialState = {
	...adapter.getInitialState(),
	loading: LoadingStatus.IDLE,
} as AccountState;

const slice = createSlice({
	name: "accounts",
	initialState,
	reducers: {},
	extraReducers: (builder) => builder
		.addCase(loadAccounts.pending.type, (state) => ({ ...state, loading: LoadingStatus.PENDING }))
		.addCase(loadAccounts.rejected.type, (state) => ({ ...state, loading: LoadingStatus.FAILURE }))
		.addCase(loadAccounts.fulfilled.type, (state, action: PayloadAction<Array<Account>>) => {
			adapter.setMany(state, action.payload);
			state.loading = LoadingStatus.SUCCESS;
		})
		.addCase(createAccount.fulfilled.type, (state, action: PayloadAction<Account>) => adapter.addOne(state, action.payload))
		.addCase(editAccount.fulfilled.type, (state, action: PayloadAction<Account>) => adapter.updateOne(state, {id: action.payload.id, changes: {
			name: action.payload.name,
			color: action.payload.color,
		}}))
		.addCase(deleteAccount.fulfilled.type, (state, action: PayloadAction<Account>) => adapter.removeOne(state, action.payload.id))
});

export { loadAccounts, createAccount, editAccount, deleteAccount };

export const useLoadAccounts = () => {
	const dispatch = useAppDispatch();
	return () => dispatch(loadAccounts());
};

export const useCreateAccount = () => {
	const dispatch = useAppDispatch();
	return (name: string, currency: string, color: Color) => dispatch(createAccount({ name, currency, color }));
};

export const useEditAccount = () => {
	const dispatch = useAppDispatch();
	return (id: number, name: string, color: Color) => dispatch(editAccount({ id, name, color }));
};

export const useDeleteAccount = () => {
	const dispatch = useAppDispatch();
	return (id: number) => dispatch(deleteAccount(id));
};

export default slice;