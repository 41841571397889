import Stack from 'react-bootstrap/Stack';
import { CreditCard, Shuffle } from 'react-bootstrap-icons';
import Modal from '../../components/Modal';
import IconButton from '../../components/IconButton';

const TopUp = () => (
	<Modal>
		<h3 className="text-white text-center">Top Up</h3>
		<Stack gap={3}>
			<IconButton variant="light" size="lg" icon={<CreditCard size={16} />} label="From another card" to="/" />
			<IconButton variant="light" size="lg" icon={<Shuffle size={16} />} label="From my accounts" to="/" />
		</Stack>
	</Modal>
);

export default TopUp;