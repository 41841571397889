import { Outlet } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import Menu from './pages/user/Menu';
import Password from './pages/user/Password';
import Photo from './pages/user/Photo';
import Address from './pages/user/Address';
import New from './pages/accounts/New';
import View from './pages/accounts/View';
import Edit from './pages/accounts/Edit';
import Send from './pages/accounts/Send';
import TopUp from './pages/accounts/TopUp';
import Transfer from './pages/accounts/Transfer';

export default [
	{
		Component: Dashboard,
		children: [
			{ path: "/", index: true, Component: Outlet },
			{
				path: "/user/*",
				Component: User,
				children: [
					{ path: "", index: true, Component: Menu },
					{ path: "password", Component: Password },
					{ path: "photo", Component: Photo },
					{ path: "address", Component: Address },
				],
			},
			{
				path: "/accounts/*",
				children: [
					{ path: "new", Component: New },
					{ path: "send", Component: Send },
					{ path: "top-up", Component: TopUp },
					{ path: "view/:id", Component: View },
					{ path: "edit/:id", Component: Edit },
					{ path: "transfer/:id", Component: Transfer },
				],
			},

		],
	}
];