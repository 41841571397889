import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../types/User';
import { LoadingStatus } from '../types/LoadingStatus';
import { useAppDispatch } from '../api';
import { login, LoginResponse, changePassword, changeAddress } from '../actions/user';

export type UserState = {
	apiKey: string,
	user: User,
	loading: LoadingStatus,
	loginStatus: LoadingStatus,
	passwordStatus: LoadingStatus,
	addressStatus: LoadingStatus,
};

const initialState = {
	apiKey: "",
	user: {},
	loading: LoadingStatus.IDLE,
	loginStatus: LoadingStatus.IDLE,
	passwordStatus: LoadingStatus.IDLE,
	addressStatus: LoadingStatus.IDLE,
} as UserState;

const slice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		logout: (state) => ({ ...state, apiKey: "" }),
	},
	extraReducers: (builder) => builder
		.addCase(login.pending.type, (state) => ({ ...state, loginStatus: LoadingStatus.PENDING }))
		.addCase(login.fulfilled.type, (state, action: PayloadAction<LoginResponse>) => ({
			...state,
			loginStatus: LoadingStatus.SUCCESS,
			apiKey: action.payload.apiKey,
			user: action.payload.user,
		}))
		.addCase(login.rejected.type, (state) => ({ ...state, loginStatus: LoadingStatus.FAILURE }))
		.addCase(changePassword.pending.type, (state) => ({ ...state, passwordStatus: LoadingStatus.PENDING }))
		.addCase(changePassword.fulfilled.type, (state) => ({...state, passwordStatus: LoadingStatus.SUCCESS }))
		.addCase(changePassword.rejected.type, (state) => ({ ...state, passwordStatus: LoadingStatus.FAILURE }))
		.addCase(changeAddress.pending.type, (state) => ({ ...state, addressStatus: LoadingStatus.PENDING }))
		.addCase(changeAddress.fulfilled.type, (state) => ({...state, addressStatus: LoadingStatus.SUCCESS }))
		.addCase(changeAddress.rejected.type, (state) => ({ ...state, addressStatus: LoadingStatus.FAILURE }))
});

export { login, changePassword, changeAddress };
export const { logout } = slice.actions;

export const useLogin = (): ((email: string, password: string) => void) => {
	const dispatch = useAppDispatch();
	return (email, password) => dispatch(login({ email, password }));
};

export const useChangePassword = (): ((oldPassword: string, newPassword: string) => void) => {
	const dispatch = useAppDispatch();
	return (oldPassword, newPassword) => dispatch(changePassword({ oldPassword, newPassword }));
};

export const useChangeAddress = (): ((address: string) => void) => {
	const dispatch = useAppDispatch();
	return (address) => dispatch(changeAddress({address}));
};

export const useLogout = (): (() => void) => {
	const dispatch = useAppDispatch();
	return () => dispatch(logout());
};

export default slice;