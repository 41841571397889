import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../reducer';
import { useAppSelector } from '../api';

const getApiKey = (state:RootState) => state.user.apiKey;
const getLoading = (state:RootState) => state.user.loading;
const getUser = createSelector(
	(state:RootState) => state.user,
	(user) => user.user,
);

const getLoginStatus = (state:RootState) => state.user.loginStatus;
const getPasswordStatus = (state:RootState) => state.user.passwordStatus;
const getAddressStatus = (state:RootState) => state.user.addressStatus;

const useApiKey = (): string => useAppSelector(getApiKey);
const useLoading = (): string => useAppSelector(getLoading);
const useUser = () => useAppSelector(getUser);

const useLoginStatus = (): string => useAppSelector(getLoginStatus);
const usePasswordStatus = ():string => useAppSelector(getPasswordStatus);
const useAddressStatus = ():string => useAppSelector(getAddressStatus);

export {
	getApiKey,
	getLoading,
	getUser,

	useApiKey,
	useLoading,
	useLoginStatus,
	usePasswordStatus,
	useAddressStatus,
	useUser,
};