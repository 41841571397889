import {
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';

export type CreditCard = {
	id: number,
	currency: string,
	balance: number,
	number: string,
	expire: Date,
};

const adapter = createEntityAdapter<CreditCard>();

const slice = createSlice({
	name: "cards",
	initialState: adapter.getInitialState(),
	reducers: {
		createCard: adapter.addOne,
		updateCard: adapter.updateOne,
		removeCard: adapter.removeOne,
	}
});

export const {
	createCard,
	updateCard,
	removeCard,
} = slice.actions;

export const {
	selectIds: getCardIDs,
	selectEntities: getCardMap,
	selectAll: getCards,
	selectTotal: countCards,
	selectById: getCard,
} = adapter.getSelectors((state:any) => state.cards);

export default slice;