import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Photo = () => (
	<div className="w-50 h-100 d-flex flex-column justify-content-between align-items-stretch p-5">
		<h4>Profile photo</h4>
		<div className="d-flex flex-column gap-2">
			<Form.Group>
				<Form.Label>Photo</Form.Label>
				<Form.Control type="file" />
			</Form.Group>
		</div>
		<div className="d-flex flex-column gap-2">
			<Button variant="dark">Save changes</Button>
			<Button variant="white">Cancel</Button>
		</div>
	</div>
);

export default Photo;