import Stack from 'react-bootstrap/Stack';
import { Plus, CreditCard, ArrowRepeat, FileEarmarkText, Shuffle } from 'react-bootstrap-icons';
import Modal from '../../components/Modal';
import IconButton from '../../components/IconButton';

const Transfer = () => (
	<Modal>
		<h3 className="text-white text-center">Top Up</h3>
		<Stack gap={3}>
			<IconButton variant="light" size="lg" icon={<Plus size={16} />} label="Top up" to="/" />
			<IconButton variant="light" size="lg" icon={<CreditCard size={16} />} label="Transfer to card" to="/" />
			<IconButton variant="light" size="lg" icon={<ArrowRepeat size={16} />} label="Currency/swap" to="/" />
			<IconButton variant="light" size="lg" icon={<FileEarmarkText size={16} />} label="By bank details" to="/" />
			<IconButton variant="light" size="lg" icon={<Shuffle size={16} />} label="Between my accounts" to="/" />
		</Stack>
	</Modal>
);

export default Transfer;