export enum ColorEnum {
	GRAY = '#FFFFFF',
	PINK = '#F3ABF4',
	MAGENTA = '#CABDFF',
	BLUE = '#B5CFF8',
	GREEN = '#B5E4CA', 
	YELLOW = '#EBF7BC'
};

export type Color = `${ColorEnum}`

export default Object.values(ColorEnum);