import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import AuthProvider from './components/AuthProvider';
import store from './api/store';
import LoginPage from './pages/Login';
import routes from './routes';
import './App.scss';

const loginRouter = createBrowserRouter([
	{ path: "/", element: <LoginPage />, errorElement: <Navigate to="/" replace={true} /> },
]);

const router = createBrowserRouter(routes);

function App() {
	return (
		<IntlProvider locale="en-US">
			<Provider store={store}>
				<AuthProvider loginPage={<RouterProvider router={loginRouter} />}>
					<RouterProvider router={router} />
				</AuthProvider>
			</Provider>
		</IntlProvider>
	);
}

export default App;
