import { createAsyncThunk, AsyncThunkOptions } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState } from './reducer';
import type { AppDispatch } from './store';
import { getApiKey } from './selectors/user';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const baseURL = "https://localhost/";

export const request = <Request, Response>(url: string, method: string, apiKey: string, data: Request): Promise<Response> => {
	return fetch(`${baseURL}${url}`, {
		method: method,
		mode: "cors",
		cache: "no-cache",
		credentials: "omit",
		redirect: "follow",
		referrerPolicy: "no-referrer",
		headers: apiKey != "" ? {
			"Authorization": `Bearer ${apiKey}`,
			"Content-Type": "application/json",
		} : { "Content-Type": "application/json" },
		body: (method != "GET") ? JSON.stringify(data) : undefined,
	}).then(res => res.json().then(data => {
		if (res.ok) {
			return data;
		} else {
			throw data;
		}
	}));
};


export const createApiThunk = <Request, Response>(action: string, method: string): any => {
	return createAsyncThunk<Response, Request, { state: RootState }>(`${method}/${action}`, (data: Request, thunkAPI): Promise<Response> => {
		return request<Request, Response>(action, method, getApiKey(thunkAPI.getState()), data);
	});
};