import { Link, useParams } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import Form from 'react-bootstrap/Form';
import { ArrowUpRight, Pencil } from 'react-bootstrap-icons';
import Offcanvas from '../../components/Offcanvas';
import IconButton from '../../components/IconButton';
import { useAccount } from '../../api/selectors/accounts';
import type { Account } from '../../api/types/Account';

const ViewAccount = () => {
	const { id = 0 } = useParams();
	const account = useAccount(id) as Account;
	const { name, currency, color } = account;
	return (
		<Offcanvas>
			<div className="d-flex flex-column">
				<div className="d-flex flex-row justify-content-around align-items-baseline p-5" style={{ backgroundColor: color }}>
					<div className="d-flex flex-column justify-content-end align-items-stretch">
						<div className="d-flex flex-row justify-content-start align-items-center mb-3">
							<h3>{name}</h3>
							<Link to={`/accounts/edit/${id}`}><Pencil className="text-muted" size={16} /></Link>
						</div>
						<div className="mb-5"><small>{currency} balance #{id}</small></div>
						<h3 className="mb-4"><FormattedNumber value={0} style="currency" currency={currency} /></h3>
						<IconButton variant="dark" size="lg" icon={<ArrowUpRight size={16} />} label="Move money" to={`/accounts/transfer/${id}`} />
					</div>
					<div className="d-flex flex-column">
						<div className="fs-5">Linked cards</div>
					</div>
				</div>
				<div className="d-flex flex-row justify-content-around align-items-baseline p-5">
					<div className="fs-5">Last activity</div>
					<Form.Control className="input-search" type="text" placeholder="Search..." />
				</div>
			</div>
		</Offcanvas>
	);
};

export default ViewAccount;