import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import BsOffcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { XLg } from 'react-bootstrap-icons';

type CanvasProps = {
	children?: JSX.Element | JSX.Element[],
}

const Offcanvas = ({ children = <Outlet /> }: CanvasProps) => {
	const [active, setActive] = useState(true);
	const navigate = useNavigate();
	const onHide = () => setActive(false);
	const onExited = () => navigate("/");
	return (
		<BsOffcanvas placement="end" show={active} onHide={onHide} onExited={onExited} data-bs-theme="dark">
			<Button className="offcanvas-close-btn" onClick={onHide}><XLg size={16} /></Button>
			<BsOffcanvas.Body>{children}</BsOffcanvas.Body>
		</BsOffcanvas>
	);
};

export default Offcanvas;