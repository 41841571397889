import { Outlet, useLocation } from 'react-router-dom';
import Figure from 'react-bootstrap/Figure';
import Offcanvas from '../components/Offcanvas';
import { useUser } from '../api/selectors/user';

const User = () => {
	const location = useLocation();
	const background = location.pathname != "/user" ? "bg-half-light" : "bg-light";
	const { name, surname, email } = useUser();
	return (
		<Offcanvas>
			<div className={`d-flex flex-row justify-content-between align-items-center ${background} h-100`}>
				<Figure className="d-flex flex-column gap-4 w-50">
					<Figure.Image width={120} height={120} roundedCircle className="d-block mx-auto bg-dark" />
					<Figure.Caption className="text-dark text-center">
						<h4>{name}</h4>
						<h4>{surname}</h4>
						<p>{email}</p>
					</Figure.Caption>
				</Figure>
				<Outlet />
			</div>
		</Offcanvas>
	);
};

export default User;