import { FormattedNumber } from 'react-intl';
import Container from 'react-bootstrap/Container';
import { useUser } from '../api/selectors/user';

const TotalBalance = () => {
	const { currency } = useUser();
	return (
		<Container className="h-100 d-flex flex-column justify-content-center">
			<h1><FormattedNumber value={0} style="currency" currency={currency} /></h1>
			<p className="text-body-tertiary text-uppercase fs-6">Total balance in <span className="text-body">{currency}</span></p>
		</Container>
	);
};

export default TotalBalance;