import { InputCursorText, Fullscreen, BoxSeam } from 'react-bootstrap-icons';
import IconButton from '../../components/IconButton';

const Menu = () => (
	<div className="d-grid justify-content-center align-items-center w-50 gap-2">
		<IconButton variant="white" size="lg" icon={<InputCursorText size={16} />} label="Edit password" to="/user/password" />
		
		<IconButton variant="white" size="lg" icon={<BoxSeam size={16} />} label="Delivery address" to="/user/address" />
	</div>
);

// <IconButton variant="white" size="lg" icon={<Fullscreen size={16} />} label="Profile photo" to="/user/photo" />

export default Menu;