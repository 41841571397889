import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

type CanvasProps = {
	children: JSX.Element | JSX.Element[],
};

const Canvas = ({ children }: CanvasProps) => {
	const [active, setActive] = useState(true);
	const navigate = useNavigate();
	const onHide = () => setActive(false);
	const onExited = () => navigate("/");
	return (
		<Modal centered={true} contentClassName="bg-transparent border-0" show={active} onHide={onHide} onExited={onExited}>
			{children}
			<Button variant="dark" size="lg" className="mt-4" onClick={onHide}>Cancel</Button>
		</Modal>
	);
};

export default Canvas;