import { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Offcanvas from '../../components/Offcanvas';
import ColorPalette from '../../components/ColorPalette';
import { useCreateAccount } from '../../api/entities/accounts';
import colors, { Color } from '../../api/types/Color';

const NewAccount = () => {
	const [name, setName] = useState("");
	const [currency, setCurrency] = useState("");
	const [color, setColor] = useState<Color>(colors[0]);
	const [valid, setValid] = useState(false);
	useEffect(() => setValid(name != "" && currency != ""), [name, currency]);
	const navigate = useNavigate();
	const createAccount = useCreateAccount();
	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		createAccount(name, currency, color);
		return navigate("/");
	};
	return (
		<Offcanvas>
			<Form onSubmit={onSubmit}>
				<div className="bg-light text-dark p-5">
					<h4 className="mb-5">Open new account</h4>
					<Stack gap={4} className="account-form m-auto">
						<Form.Group>
							<Form.Label className="fs-5">Account name</Form.Label>
							<Form.Control type="text" value={name} onChange={e => setName(e.currentTarget.value)} />
						</Form.Group>
						<Form.Group>
							<Form.Label className="fs-5">Currency</Form.Label>
							<Form.Select value={currency} onChange={e => setCurrency(e.currentTarget.value)}>
								<option>GBP</option>
								<option>USD</option>
								<option>EUR</option>
							</Form.Select>
						</Form.Group>
						<Form.Group>
							<Form.Label className="fs-5">Color label</Form.Label>
							<ColorPalette value={color} onChange={setColor} />
						</Form.Group>
					</Stack>
				</div>
				<div className="bg-white p-5 text-center">
					<Button type="submit" variant="dark" className="w-50" size="lg" disabled={!valid}>Create</Button>
				</div>
			</Form>
		</Offcanvas>
	);
};

export default NewAccount;